import { Button, Result } from "antd";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const Page: NextPage = () => {
  const router = useRouter();
  const [countdown, setCountdown] = useState(2);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        const value = prev - 1;
        if (value === 0) {
          router.replace("/");
        }
        return value;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    }
  }, [router]);

  return (
    <div style={{ height: "100vh" }} className="flex-row-center">
      <Result
        status="404"
        title="404"
        subTitle={`抱歉，这个页面并不存在，${countdown}秒后自动跳转`}
        extra={
          <Button style={{ color: "white" }} type="primary" onClick={() => router.replace("/")}>
            回到首页
          </Button>
        }
      />
    </div>
  );
};

export default Page;
